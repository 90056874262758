<template>
  <div>
    {{ $t('userTabs.userList') }}
    <v-data-table
      :headers="headers"
      :items="users"
      :loading="isLoading"
      :header-props="headerProps"
      :items-per-page="5"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ $t('forms.editUser') }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.firstName"
                      :label="$t('userRegistration.firstName')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.lastName"
                      :label="$t('userRegistration.lastName')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      readonly
                      v-model="editedItem.email"
                      :label="$t('userRegistration.email')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.phone"
                      :label="$t('userRegistration.phone')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6" v-if="isAdmin">
                    <v-select
                      v-model="editedItem.role"
                      :items="roles"
                      :label="$t('userRegistration.selectRole')"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-if="clients"
                      v-model="selectedClients"
                      :items="selectItems"
                      :label="$t('userRegistration.selectCompany')"
                      deletable-chips
                      multiple
                      small-chips
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-if="isAdmin"
                      v-model="selectedPipelines"
                      :items="selectPipelines"
                      :label="$t('userRegistration.selectPipelines')"
                      deletable-chips
                      multiple
                      small-chips
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                {{ $t('wizard.cancel') }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                {{ $t('wizard.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:item.name="{ item }">
        <span>
          {{ item.firstName + ' ' + item.lastName }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon> -->
        <v-icon small @click="editUser(item)"> mdi-pencil </v-icon>
        <v-icon v-if="isAdmin" small @click="impersonateUser(item)">
          mdi-card-account-details
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data: function () {
    return {
      selectItems: [],
      isLoading: true,
      editedItem: {
        permissions: [],
      },
      selectedClients: [],
      selectedPipelines: [],
      editedIndex: -1,
      dialog: false,
      headers: [
        { text: this.$i18n.t('headers.name'), value: 'name' },
        { text: this.$i18n.t('headers.email'), value: 'email' },
        { text: this.$i18n.t('headers.role'), value: 'role' },
        {
          text: this.$i18n.t('headers.actions'),
          value: 'actions',
          sortable: false,
        },
      ],
      roles: [
        {
          text: 'Admin',
          value: 'admin',
        },
        {
          text: 'Account',
          value: 'account',
        },
        {
          text: 'Client',
          value: 'client',
        },
      ],
    }
  },
  methods: {
    editUser(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
      this.selectedClients = []
      this.selectedPipelines = []
      if (this.editedItem.permissions) {
        this.editedItem.permissions.forEach((p) => {
          this.selectedClients.push(p.clientId)
        })
      }
      if (this.editedItem.pipelines) {
        this.editedItem.pipelines.forEach((p) => {
          this.selectedPipelines.push(p.pipelineId)
        })
      }
    },
    impersonateUser(item) {
      this.$store.dispatch('user/impersonateUser', item.id)
    },
    close() {
      this.dialog = false
    },
    save() {
      let { firstName, lastName, email, id, phone } = this.editedItem
      const payload = Object.assign(
        {},
        { id, firstName, lastName, email, phone }
      )
      if (this.isAdmin) {
        payload.role = this.editedItem.role
        payload.pipelines = this.selectedPipelines.map((p) => ({
          pipelineId: p,
        }))
      }
      payload.permissions = this.selectedClients.map((c) => ({ clientId: c }))
      this.$store.dispatch('user/updateUser', payload).then(() => {
        this.$store.dispatch('snackbar/addSnackbar', {
          text: `${payload.firstName} ${payload.lastName} changes registered successfully.`,
          color: 'success',
        })
      })
      this.dialog = false
    },
  },
  created() {
    this.$store.dispatch('user/getUsers')
    this.$store.dispatch('client/getClients')
  },
  computed: {
    ...mapState({
      users: (state) => state.user.users,
      clients: (state) => state.client.clients,
      pipelines: (state) => state.pipeline.pipelines,
    }),
    isAdmin() {
      return this.$store.getters['user/role'] == 'admin'
    },
    headerProps() {
      return {
        sortByText: this.$i18n.t(`dataTable.sortBy`),
      }
    },
    selectPipelines() {
      return this.pipelines.map((p) => ({
        text: p.name,
        value: p.id,
      }))
    },
  },
  watch: {
    users() {
      if (this.users.length > 0) {
        this.isLoading = false
      }
    },
    clients() {
      this.selectItems = this.clients.map((c) => ({
        text: c.name,
        value: c.id,
      }))
    },
  },
}
</script>

<style lang="scss" scoped></style>
